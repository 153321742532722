.ubf-header {
	// pointer-events: none;
	// used to apply the container max size and the fixed position to all the header parts
	// cannot be applied directly to the parent because this will create a stacking context,
	// making impossible to have some parts above and other parts behind the content
	@mixin headerContainer() {
		@include ubf-container();
		left: 50%;
		position: fixed;
		top: 0;
		transform: translateX(-50%);
	}

	@include storeValuesForJs((headerEffectEnabled: true, triggerMargin: remToPx($headerHeightSmall + $base4)));

	@include media($xlargeLayoutBreakpoint) {
		@include storeValuesForJs((headerEffectEnabled: false));
	}

	.ubf-body--noCover & {
		@include storeValuesForJs((headerEffectEnabled: true, triggerMargin: remToPx($headerHeightSmall + $base)));

		@include media($xlargeLayoutBreakpoint) {
			@include storeValuesForJs((headerEffectEnabled: false));
		}
	}


	// used as a white background for the header, only on small screens
	&::after {
		@include fadeOut($duration2);
		background-color: $colorBgBase;
		content: '';
		left: 0;
		height: $headerHeightSmall;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 5;

		@include media($xlargeLayoutBreakpoint) {
			display: none;
		}
	}

	&.ubf-js-overlapping::after {
		@include fadeIn();
	}

	&__facultyLink {
		color: $colorBase;
		&:hover {
			color: $colorBase;
		}
	}

	&__facultyName {
		@include headerContainer();
		z-index: 3;

		.ubf-header.ubf-js-overlapping & {
			display: none;

			@include media($xlargeLayoutBreakpoint) {
				display: block;
			}
		}
	}


	&__facultySvg {
		@include mediaMap($sizeVariants, facultyGeometry);
		// fix the alignment with the logo, because the name svg is containing 1px empty around
		// margin-top: 1px;
		position: absolute;

		html.no-js & {
			width: $base * 14;

			@include media($xlargeLayoutBreakpoint) {
				width: $base * 18;
			}
		}
	}

	&__campaignLink {
		pointer-events: all;
	}

	&__homeLink {
		pointer-events: all;
	}


	&__logo {
		@include headerContainer();
		z-index: 10;


		&::before {
			@include mediaMap($sizeVariants, logoBarGeometry);
			@include ubf-highlightColor(background-color);
			content: '';
			display: none;
			position: absolute;

			.ubf-body--faculty & {
				display: block;
			}
		}
	}


	&__logoSvg {
		@include mediaMap($sizeVariants, logoGeometry);
		color: $colorBase;
		position: absolute;
	}


	&__mainMenuToggler {
		@include headerContainer();
		// pointer-events: all;
		z-index: 6;
		height:50px;

		html.no-js & {
			z-index: 4;
		}

		@include media($xlargeLayoutBreakpoint) {
			display: none;
		}
	}

	&__mainMenuTogglerLink {
		width: $menuTogglerWidth;
		height: 50px;
		// height: $menuTogglerHeight + $menuTogglerOffset;
		margin-top: -($menuTogglerHeight * 0.5);
		position: absolute;
		right: $base;
		top: $headerHeightSmall * 0.5;

		@include media($ibridLayoutBreakpoint) {
			right: $base + $logoSizeSmall - $menuTogglerWidth;
		}
	}


	&__name {
		@include headerContainer();
		z-index: 6;

		@include media($xlargeLayoutBreakpoint) {
			z-index: 2;
		}

		.ubf-body--ekvv & {
			@include media($xlargeLayoutBreakpoint) {
				z-index: 11;
			}
		}
	}


	&__nameSvg {
		@include ubf-outlineOnFocus('.ubf-header__homeLink', '&');
		@include mediaMap($sizeVariants, nameGeometry);
		color: $colorBase;
		position: absolute;


		html.no-js & {
			width: $base * 10.1;

			@include media($xlargeLayoutBreakpoint) {
				width: $base * 13.1;
			}
		}
	}

	&__campaingLink {
		@include ubf-outlineOnFocus();
	}

	&__campaignSvg {
		@include ubf-outlineOnFocus('.ubf-header__campaignLink', '&');
		@include mediaMap($sizeVariants, campaignGeometry);
		position: absolute;

		html.no-js & {
			width: $base * 10.9;

			@include media($xlargeLayoutBreakpoint) {
				width: $base * 13.4;
			}
		}
	}
}
