.ubf-breadcrumbs {
	font-size: 0;	// it removes the space between inline-block elements, the actual font size is set inside
	// padding: 0 0 $base 0;

	&--bordered {
		@include ubf-highlightColor(border-top-color);
		@include ubf-highlightColor(border-bottom-color, 0.33);
		border-width: $boxBorderSize 0 $boxBorderSize 0;
		border-style: solid;
	}

	&__item {
		display: inline-block;

		&:not(:last-child) {
			margin-right: $base;
		}
	}


	&__label {
		@include ubf-font(base);
		color: $colorSubNav;
	}


	&__icon {
		@include ubf-highlightColor(color);
		display: inline-block;
		padding-left: $base;

		& span[data-type="svg"] {
			width: $base1;
			height: $base1;
		}
	}


	&__link {
		@include ubf-highlightColor(color);
		text-decoration: none;

		&:hover,
		&:focus {
			outline: 0;
			text-decoration: underline;
		}
	}
}
