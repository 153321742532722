.ubf-contentBlock {
	@mixin canBeNestedInSidebar() {
		.ubf-body .ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}
	}
	position: relative;
	order: 1;
	width: dynamicColumnSize(1, 1, $gridGutterSmall); // full width by default, can be customized inside modifiers

	.ubf-sidebar > &:first-of-type {
		@include ubf-highlightColor(border-top-color);
		border-top-style: solid;
		border-top-width: $boxBorderSize;
		padding-top: $base2;
	}

	.ubf-sidebar > &--textBoxed:first-of-type {
		padding-top: 0;
	}

	@include media($largeLayoutBreakpoint) {
		width: dynamicColumnSize(1, 1, $gridGutterLarge);
	}

	.ubf-field .ubf-field__description & {
		width: dynamicColumnSize(1, 1, $gridGutterLarge);

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(1, 1, $gridGutterLarge);
		}
	}

	&--accordion {
		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(9, 12, $gridGutterLarge);
		}

		.ubf-body--internal &,
		.ubf-body--ekvv &,
		.ubf-body--generic & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}

		&.ubf-contentBlock--accordionReduced {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 12, $gridGutterLarge);
			}

			.ubf-body--internal &,
			.ubf-body--ekvv & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(6, 9, $gridGutterLarge);
				}
			}
		}
	}

	&--contact {
		@include canBeNestedInSidebar();

		.ubf-contentBlock--accordion {
			width:100%!important;
		}

		&.ubf-contentBlock--contactReduced {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 9, $gridGutterLarge);
			}
			.ubf-contentBlock--accordion .ubf-contentBlock--text {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(1, 1, $gridGutterLarge);
				}
			}
		}
	}


	&--address {
		@include canBeNestedInSidebar();

		@include ubf-highlightColor(border-color);
		border-bottom-style: solid;
		border-bottom-width: $boxBorderSize;
		padding-bottom: $base2;

		.ubf-sidebar > &:first-of-type {
			border: none;
			padding-top: 0;
		}

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(3, 12, $gridGutterLarge);
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(3, 9, $gridGutterLarge);
			}
		}

		.ubf-body .ubf-contentBlock--accordionReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 2, $gridGutterLarge);
			}
		}
	}
	&--disturber {
		@include ubf-highlightColor(border-color);
		border-style: solid;
		border-width: $boxBorderSize;
    	display: flex;
    	align-items: center;
   		padding: 2px 0px;
	}

	&--alphabeticalList {
		position: relative;
		margin-top: $base4;

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 9, $gridGutterLarge);
			}
		}

		.ubf-body--ekvv & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 9, $gridGutterLarge);
			}
		}
	}

	&--assignmentPlanLegend {
		position: relative;

		@include media($largeLayoutBreakpoint) {
			max-width: ubf-ekvvMaxSize(6, 9);
			width: dynamicColumnSize(6, 9, $gridGutterLarge);
		}
	}

	&--applicationTile {
		> * + * {
			margin-top: $base4;
		}
	}

	&--breadcrumbs {
		.ubf-section__content > & {
			margin-bottom: $base;
		}

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(12, 12, $gridGutterLarge);
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(9, 9, $gridGutterLarge);
			}
		}
	}

	&--calendar {
		@include canBeNestedInSidebar();

		.ubf-sidebar > &:first-of-type {
			border: none;
			padding-top: 0;
		}

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(5, 12, $gridGutterLarge);
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(5, 9, $gridGutterLarge);
			}
		}

		.ubf-body .ubf-contentBlock--accordionReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}
	}

	&--coursesFeatures {
		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(9, 12, $gridGutterLarge);
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 9, $gridGutterLarge);
			}
		}
	}

	&--events {
		@include canBeNestedInSidebar();
		position: relative;

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(6, 12, $gridGutterLarge);
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 9, $gridGutterLarge);
			}
		}

		& + & {
			margin-top: $base4;

			@include media($largeLayoutBreakpoint) {
				margin-top: $base8;
			}

			.ubf-sidebar & {
				@include media($largeLayoutBreakpoint) {
					margin-top: $base2;
				}
			}
		}
	}

	&--eventsDetails {
		@include canBeNestedInSidebar();

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(7, 12, $gridGutterLarge);
		}

		.ubf-body .ubf-contentBlock--accordionReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}
	}

	&--form {
		@include canBeNestedInSidebar();
		position: relative;

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(9, 12, $gridGutterLarge);
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(9, 9, $gridGutterLarge);
			}
		}

		.ubf-body--ekvv & {
			@include media($largeLayoutBreakpoint) {
				max-width: ubf-ekvvMaxSize(6, 9);
				width: dynamicColumnSize(9, 9, $gridGutterLarge);
			}
		}

		&.ubf-contentBlock--formSmall {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 12, $gridGutterLarge);
			}

			.ubf-body--internal & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(6, 9, $gridGutterLarge);
				}
			}

			.ubf-body--ekvv & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(6, 9, $gridGutterLarge);
				}
			}
		}

		.ubf-sidebar &,
		.ubf-sidebar &.ubf-contentBlock--formSmall {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}

		.ubf-secondaryMenu &,
		.ubf-secondaryMenu &.ubf-contentBlock--formSmall {
			width: 100%;

			@include media($largeLayoutBreakpoint) {
				width: 100%;
			}
		}

		.ubf-body .ubf-contentBlock--accordionReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}

		&::before {
			content: "";
			display: block;
			// solving a negative margin issue
			height: 0.001px;
			line-height: 0;
		}
	}

	&--highlights {
		// no custom version until now
	}

	&--highlightLinks {
		.ubf-contentBlock--sidebar + & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(9, 12, $gridGutterLarge);
			}
		}

		.ubf-body--internal .ubf-contentBlock--sidebar + & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 9, $gridGutterLarge);
			}
		}

		&.ubf-contentBlock--reduced {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 12, $gridGutterLarge);
			}

			.ubf-contentBlock--sidebar + & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(9, 12, $gridGutterLarge);
				}
			}

			.ubf-body--internal & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(6, 9, $gridGutterLarge);
				}
			}
		}
	}

	&--gallery {
		// no custom version until now
	}

	&--pagination {
		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(6, 12, $gridGutterLarge);
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 9, $gridGutterLarge);
			}
		}

		.ubf-body .ubf-contentBlock--accordionReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}

		* + & {
			margin-top: $base4;
		}
	}

	&--pevzSearch {
		.ubf-secondaryMenu & {
			display: none;

			@include media($largeLayoutBreakpoint) {
				display: block;
			}
		}

		.ubf-page__sections & {
			@include media($largeLayoutBreakpoint) {
				display: none;
			}
		}
	}

	&--pvSearch {
		.ubf-secondaryMenu & {
			display: none;

			@include media($largeLayoutBreakpoint) {
				display: block;
			}
		}

		.ubf-page__sections & {
			@include media($largeLayoutBreakpoint) {
				display: none;
			}
		}
	}

	&--previews {
		&.ubf-contentBlock--previewsReduced {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 12, $gridGutterLarge);
			}

			.ubf-contentBlock--sidebar + & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(9, 12, $gridGutterLarge);
				}
			}

			.ubf-body--internal & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(6, 9, $gridGutterLarge);
				}
			}
		}

		.ubf-body .ubf-contentBlock--accordionReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}
	}

	&--featuredLinks {
		&.ubf-contentBlock--featuredLinksReduced {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 12, $gridGutterLarge);
			}

			.ubf-contentBlock--sidebar + & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(9, 12, $gridGutterLarge);
				}
			}

			.ubf-body--internal & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(6, 9, $gridGutterLarge);
				}
			}
		}

		.ubf-body .ubf-contentBlock--accordionReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}
	}

	&--rxml {
		@include canBeNestedInSidebar();
		position: relative;
	}

	&--scrollTopButton {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1;
		display: flex;
		justify-content: flex-end;

		@include media($largeLayoutBreakpoint) {
			display: block;
			width: dynamicColumnSize(12, 12, $gridGutterLarge);
		}

		.ubf-section--searchFilters + .ubf-section & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 6, $gridGutterLarge);
			}
		}
	}

	&--searchResults {
		position: relative;

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(6, 12, $gridGutterLarge);
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 9, $gridGutterLarge);
			}
		}
	}

	&--sidebar {
		order: 2;

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(3, 12, $gridGutterLarge);
		}

		&.ubf-contentBlock--sidebarSticky,
		&:first-child {
			@include media($largeLayoutBreakpoint) {
				float: right;
			}
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(3, 9, $gridGutterLarge);
			}
		}

		.ubf-body--ekvv & {
			@include media($largeLayoutBreakpoint) {
				max-width: ubf-ekvvMaxSize(3, 9);
				width: dynamicColumnSize(3, 9, $gridGutterLarge);
			}
		}
	}

	&--slideshow {
		position: relative;

		.ubf-section--cover & {
			width: 100%;
			height: 100%;
		}
	}

	&--tabs {
		// no custom version until now
	}

	&--text {
		@include canBeNestedInSidebar();
		position: relative;

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(6, 12, $gridGutterLarge);
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 9, $gridGutterLarge);
			}
		}

		.ubf-body--ekvv & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 9, $gridGutterLarge);
			}
		}

		.ubf-section--searchFilters + .ubf-section & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 6, $gridGutterLarge);
			}
		}

		&.ubf-contentBlock--textFull {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(9, 12, $gridGutterLarge);
			}

			.ubf-body--ekvv &,
			.ubf-body--internal & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(9, 9, $gridGutterLarge);
				}
			}
		}

		&.ubf-contentBlock--textMedium {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(5, 12, $gridGutterLarge);
			}

			.ubf-body--internal & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(5, 9, $gridGutterLarge);
				}
			}
		}

		&.ubf-contentBlock--textSmall {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(4, 12, $gridGutterLarge);
			}

			.ubf-body--internal & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(4, 9, $gridGutterLarge);
				}
			}
		}

		.ubf-body .ubf-contentBlock--accordionReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}

		.ubf-body--ekvv &.ubf-contentBlock--tableFull {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(9, 9, $gridGutterLarge);
			}
		}
	}

	&--textBox {
		@include canBeNestedInSidebar();

		@include media($largeLayoutBreakpoint) {
			@include minAspectRatio(1);
			width: dynamicColumnSize(5, 12, $gridGutterLarge);
		}

		.ubf-body .ubf-contentBlock--accordionReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}

		.ubf-section--cover & {
			@include minAspectRatio(1);
			@include ubf-coverTextBox();
			z-index: 101;
		}
	}

	&--video {
		position: relative;

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(9, 12, $gridGutterLarge);
		}

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}

		&.ubf-contentBlock--videoSmall {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(6, 12, $gridGutterLarge);
			}

			.ubf-body--internal & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(6, 9, $gridGutterLarge);
				}
			}
		}

		&.ubf-contentBlock--videoLarge {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}

			.ubf-body--internal & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(1, 1, $gridGutterLarge);
				}
			}
		}

		.ubf-body .ubf-contentBlock--accordionReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}
	}

	&__template {
		display: none;
	}

	&__eventsDetails {
		min-height: 100%;
	}

	&__slideshow {
		// needed to fit the height when used inside the cover section
		height: 100%;
	}

	&__title {
		@include ubf-font(h3);
		margin-bottom: $base2;

		&--important {
			@include ubf-font(h2);
		}

		&--withActions {
			position: relative;
			@include media($largeLayoutBreakpoint) {
				@include staticColumns();
			}
		}

		.ubf-secondaryMenu & {
			@include ubf-font(h);
		}

		.ubf-contentBlock:not(.ubf-contentBlock--sidebar)
			+ .ubf-contentBlock:not(.ubf-contentBlock--sidebar)
			&:first-child {
			margin-top: $base4;

			.ubf-sidebar & {
				margin-top: 0;
			}
		}

		.ubf-contentBlock.ubf-contentBlock--pevzSearch
			+ .ubf-contentBlock:not(.ubf-contentBlock--sidebar)
			& {
			@include media($largeLayoutBreakpoint) {
				margin-top: 0;
			}
		}

		.ubf-contentBlock--secondaryMenu & {
			color: $colorSubNav;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.ubf-body--ekvv & {
			@include media($largeLayoutBreakpoint) {
				max-width: ubf-ekvvMaxSize(5, 9);
			}
		}

		.ubf-contentBlock__preContent + & {
			margin-top: 0;
		}
	}

	&__preContent {
		.ubf-contentBlock:not(.ubf-contentBlock--sidebar)
			+ .ubf-contentBlock:not(.ubf-contentBlock--sidebar)
			&:first-child {
			margin-top: $base4;

			.ubf-sidebar & {
				margin-top: 0;
			}
		}
	}

	&__noJs {
		display: none;

		.ubf-html.no-js & {
			display: block;
		}
	}

	&__titleActions {
		margin-top: $baseHalf;

		@include media($largeLayoutBreakpoint) {
			margin-top: 0;
			width: staticColumnSize(2, 12, $gridGutterLarge);
		}
	}

	&__titleInfo {
		@include ubf-font(base);
		display: inline-block;
		font-weight: normal;
		margin-left: $base2;
	}

	&__titleText {
		.ubf-contentBlock__title--withActions & {
			@include media($largeLayoutBreakpoint) {
				width: staticColumnSize(10, 12, $gridGutterLarge);
			}
		}
	}
}
