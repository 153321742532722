@charset "UTF-8";

@function unicode($str) {
	@return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"")
}

.ubf-ugc {
	// User Generated Content Style

	&::after {
		content: '';
		display: block;
		visibility: hidden;
		opacity: 0;
		clear: both;
	}

	img {
		max-width: 100%!important;
		height: auto;
		/* vb-03.05.2021: Fix for iOS images */
		align-self: flex-start;
	}


	a:not(.ubf-button) {
		@include ubf-link();
		z-index: 1;

		.ubf-textBox & {
			@include ubf-link($boxed: true);

			.ubf-sidebar & {
				@include media($largeLayoutBreakpoint) {
					@include ubf-link();
				}
			}
		}

		.ubf-text & {
			font-weight: normal;
		}

		&.ubf-ugc__link--iconLink {
			display: flex;

			&:first-of-type {
				margin-top: $base2;
			}

			p {
				margin-top: 0;
				margin-left: $base1;
			}
		}

		&.ubf-ugc__link--subtleLink,

		&.ubf-excerpt__link {
			text-decoration: none;
		}

		&.ubf-excerpt__link:hover {
			text-decoration: underline;
		}

		&.ubf-ugc__link--subtleLinkAlternate {
			color: $colorEkvvLinkAlternate;
		}
	}


	&__zoomImage {
		position: relative;
		pointer-events: none;

		@include media($largeLayoutBreakpoint) {
			pointer-events: all;
		}
	}


	button:not(.ubf-button) {
		border: 0;
		vertical-align: middle;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}


	button:not(:only-child):not(:last-of-type):not(&__nav--buttonPrev) {
		margin-bottom: $base2;
		margin-right: $base2;
	}


	figcaption {
		@include ubf-font(caption);
		margin-top: $base;
		text-align: right;
	}


	figure {
		@include ubf-outlineOnFocus('.ubf-ugc__zoomImage', '&');
		width: 100%;

		.ubf-html:not(.no-js) & {
			@include ifSupportCustomProperties() {
				// @include minAspectRatio(var(--w));
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;

				&::before {
					content: '';
					display: block;
					flex-grow: 0;
					flex-shrink: 1;
					width: 0;
					padding-bottom: calc(100% / (var(--w) / var(--h)));
				}
			}
		}

		img {
			width: 100%;
		}

		.ubf-html:not(.no-js) img:not(.ubf-js-loaded) {
			height: 1px;
			visibility: hidden;
			opacity: 0;
		}

		figcaption {
			width: 100%;
		}
	}


	& &__image {
		@mixin noFloat($resetWidth: true) {
			@include media($largeLayoutBreakpoint) {
				float: none;
				margin-bottom: 0;
				margin-right: 0;
				@if ($resetWidth) {
					width: 100%;
				}
			}
		}

		@mixin floatRight() {
			@include media($largeLayoutBreakpoint) {
				float: right;
				margin-left: $gridGutterLarge;
				margin-right: 0;
			}
		}

		&--medium {
			@include media($largeLayoutBreakpoint) {
				float: left;
				margin-bottom: $gridGutterLarge;
				margin-right: $gridGutterLarge;
				width: staticColumnSize(4, 6, $gridGutterLarge);
			}

			&.ubf-ugc__image--right {
				@include floatRight();

				.ubf-sidebar & {
					@include noFloat();
				}
			}

			&.ubf-ugc__image--noFloat {
				@include noFloat(false);
			}

			.ubf-sidebar & {
				@include noFloat();
			}
		}


		&--small {
			@include media($largeLayoutBreakpoint) {
				float: left;
				margin-bottom: $gridGutterLarge;
				margin-right: $gridGutterLarge;
				width: staticColumnSize(3, 6, $gridGutterLarge);
			}

			&.ubf-ugc__image--right {
				@include floatRight();

				.ubf-sidebar & {
					@include noFloat();
				}
			}

			&.ubf-ugc__image--noFloat {
				@include noFloat(false);
			}

			.ubf-sidebar & {
				@include noFloat();
			}
		}
	}


	q,
	blockquote {
		quotes: "\00BB" "\00AB";
	}


	blockquote {
		@include ubf-font(h3);
		@include ubf-highlightColor(border-left-color);
		font-weight: normal;
		margin: 0;
		padding-left: $base2;
		border-left-style: solid;
		border-left-width: $boxBorderSize;
		line-height: 1.6!important;

		> p:first-child::before {
			content: unicode("00BB");
		}

		> p:last-of-type::after {
			content: unicode("00AB");
		}

		.ubf-preview &,
		.ubf-sidebar & {
			@include ubf-font(base);
			padding-left: 0;
			border-left: 0;
		}
	}


	cite {
		@include ubf-font(base);
		display: block;
		font-style: normal;
		text-align: right;


		&::before {
			content: '\2014';
			margin-right: 0.2em;
		}
	}

	blockquote cite {
		margin-top: $base;
	}

	q + cite {
		display: inline;
		margin-left: $base;
	}


	q {
		font-style: normal;
		color: $colorInlineQuote;
	}


	dl {
		margin: 0;

		&.ubf-ugc__dl--compact {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;
		}

		&.ubf-ugc__dl--splitted {
			display: flex;
			flex-wrap: wrap;
		}

		& .ubf-ugc__dlItemsWrapper {
			margin-top: $base2;
			width: 100%;

			@include media($largeLayoutBreakpoint) {
				width: staticColumnSize(3, 6, $gridGutterLarge);
			}
		}

		&.ubf-ugc__dl--floating {
			display: flex;
			flex-direction: column;

			@include media($largeLayoutBreakpoint) {
				flex-direction: row;
			}
		}

		&.ubf-ugc__dl--spreaded {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;
		}
	}


	dt {
		@include ubf-font(base);
		display: block;
		font-weight: bold;
	}


	.ubf-ugc__dl--compact dt {
		flex-grow: 0;
		flex-shrink: 0;
		font-weight: normal;
		padding-right: $base;
		width: 30%;
	}


	.ubf-ugc__dl--floating dt {
		font-weight: normal;
		width: auto;
		margin-top: $base1;

		@include media($largeLayoutBreakpoint) {
			margin-top: 0;
		}
	}

	.ubf-ugc__dl--spreaded dt {
		flex-grow: 0;
		flex-shrink: 0;
		font-weight: normal;
		padding-right: $base;
		width: 100%;

		@include media($largeLayoutBreakpoint) {
			width: 30%;
		}
	}



	dd {
		margin: 0;
	}


	.ubf-ugc__dl--compact dd {
		flex-grow: 0;
		flex-shrink: 0;
		width: 70%;
	}


	.ubf-ugc__dl--compact dd + dd {
		margin-left: 30%;
	}


	.ubf-ugc__dl--floating dd {
		width: auto;
		margin: 0;

		@include media($largeLayoutBreakpoint) {
			flex-direction: row;
			margin: 0 $base2 0 $base1 * 0.5;
		}
	}


	.ubf-ugc__dl--spreaded dd {
		flex-grow: 0;
		flex-shrink: 0;
		width: 100%;

		@include media($largeLayoutBreakpoint) {
			width: 70%;
		}

		& > * {
			margin-left: 0;

			@include media($largeLayoutBreakpoint) {
				margin-left: $base6;
			}
		}

		& > *:not(:last-of-type) {
			margin-right: $base2;

			@include media($largeLayoutBreakpoint) {
				margin-right: 0;
			}
		}
	}

	small {
		@include ubf-font(weekEventInfo);
	}


	// this should never be used inside content,
	// we set the same size as h2 to discourage any use to obtain a bigger title
	h1 {
		@include ubf-font(h2);

		.ubf-sidebar & {
			@include ubf-font(h3);
		}
	}


	h2 {
		@include ubf-font(h2);

		.ubf-sidebar & {
			@include ubf-font(h3);
		}
	}


	h3 {
		@include ubf-font(h3);

		.ubf-sidebar & {
			@include ubf-font(h);
		}
	}


	h4,
	h5,
	h6 {
		@include ubf-font(h);
	}


	hr {
		@include ubf-highlightColor(background-color);
		border: 0;
		height: $boxBorderSize;
		margin: 0;
		width: 100%;

		&.ubf-ugc__separator--seamless {
			background-color: transparent;
			height: 0;
		}
	}

	li {
		margin: 0;
	}

	ol > li {
		padding-left: 0.4em;
	}


	ol {
		margin: 0 0 0 1em;
	}


	ul {
		margin: 0 0 0 $base2;
	}

	ul > li {
		position: relative;

		&::before {
			content: '\2022';
			position: absolute;
			top: 0;
			transform-origin: 0 50%;
			transform: scale(1.5);
			left: -$base2;
		}
	}


	figure.ubf-ugc__image ~ ul > li,
	a.ubf-ugc__zoomImage ~ ul > li {
		position: relative;

		&::before {
			content: '\2022';
			@include ubf-font(base);
			display: inline-block;
			margin-right: $base1;
			position: static;
			transform-origin: 0 50%;
			transform: scale(1.5);
		}
	}


	ul.ubf-ugc__list--indentationFreeList {
		margin-left: 0;

		> li {
			margin-top: $base2;
		}

		> li::before {
			content: '';
			display: none;
		}

		> li p {
			margin-top: 0;
		}
	}


	ul.ubf-ugc__list--rowList {
		display: flex;
		list-style-type: none;
		margin-left: 0;
		flex-wrap: wrap;

		@include media($largeLayoutBreakpoint) {
			justify-content: space-between;
		}
	}


	li.ubf-ugc__listItem--rowListItem {
		@include ubf-font(h3);
		margin: 0 $base1 * 0.5;

		@include media($largeLayoutBreakpoint) {
			margin: 0;
		}

		&::before {
			content: '';
			display: none;
		}


		a {
			text-decoration: none;
		}


		p {
			color: $colorAlphabeticalLetterInactive;
		}


		p > a {
			color: $colorBase;
		}
	}

	ul.ubf-ugc__list--nestedList > .ubf-ugc__listItem--nestedList {
		list-style-type: none;

		&::before {
			content: '';
			display: none;
		}
	}


	&__nav {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.ubf-form__fields + & {
			margin-top: $base;
		}

		.ubf-html.no-js & {
			display: none;
		}

		&--buttonPrev {
			transform: rotate(180deg);
			margin: 0;
			opacity: 0.5;
			cursor: default;

			&:focus {
				@include ubf-outlineOnFocus();
			}
			&.ubf-js-enabled {
				opacity: 1;
				cursor: pointer;
			}
		}

		&--buttonNext {
			opacity: 0.5;
			cursor: default;
			&:focus {
				@include ubf-outlineOnFocus();
			}
			&.ubf-js-enabled {
				opacity: 1;
				cursor: pointer;
			}
		}

		&--container {
			@include storeValuesForJs((indicatorOffset: 10));
			margin-left: $base1;
			margin-right: $base1;
			background: $colorIndicatorBg;
			display: block;
			height: $base * 0.2;
			overflow: hidden;
			position: relative;
			width: 100%;
			opacity: 0.5;
		}

		&--indicator {
			background: $colorBase;
			height: $base * 0.2;
			left: 0;
			position: absolute;
			top: 0;
			// transform: translateX(-100%);
			width: 50%;
		}

	}


	table {
		display: block;
		width: 100%;

		@include media($largeLayoutBreakpoint) {
			display: table;
		}

		.ubf-body--ekvv & {
			background-color: $colorBgSemesterTable;
		}

		&.ubf-ugc__table--compareLayout {
			.ubf-html:not(.no-js) & {
				display: block;
				overflow-x: hidden;
			}
		}
	}


	tbody,
	caption {
		display: block;

		@include media($largeLayoutBreakpoint) {
			display: table-row-group;
		}
	}


	caption {
		@include ubf-font(caption);
		caption-side: bottom;
		border-top: $tableCellBorderSize solid $colorTableCellBorder;
		padding: $baseHalf 0 0 0;
		text-align: right;
	}


	tr {
		display: block;

		&.ubf-js-hidden {
			display: none;
		}

		@include media($largeLayoutBreakpoint) {
			display: table-row;
		}
	}


	&__table--alternative {
		tr:nth-child(2n + 1) {
			@include media($largeLayoutBreakpoint) {
				background-color: $colorBgTableAlternativeRows;
			}
		}

		.ubf-body--ekvv & tr:first-child {
			border-bottom: $tableCellBorderSize solid $colorEkvvTableBorder;
		}

		.ubf-body--ekvv & tr:nth-child(2n + 1) {
			background-color: $colorBgSemesterTable;
		}

		.ubf-body--ekvv &  tr:nth-child(2n + 2) {
			background-color: $colorBgBase;
		}
	}


	&__table--semesterTable tr {
		display: flex;
		flex-wrap: wrap;

		@include media($largeLayoutBreakpoint) {
			display: table-row;
		}

		&:not(:last-child):not(:first-child) {
			border-bottom: $colorEkvvTableBorder solid $tableHeaderBorderSize;

			@include media($largeLayoutBreakpoint) {
				border-bottom: 0;
			}
		}

		&:first-child {
			border-bottom: $colorEkvvTableBorder solid $tableHeaderBorderSize;
		}

		// nth child repeticion needed to override alternative variant
		&:nth-child(2n + 1) {
			background-color: $colorBgSemesterTable;
		}

		&:nth-child(2n + 2) {
			background-color: $colorBgSemesterTable;

			@include media($largeLayoutBreakpoint) {
				background-color: $colorBgBase;
			}
		}
	}



	&__table--verticalLayout {
		.ubf-body & tr:not(:first-child) th {
			display: block;
			padding-top: $base1;

			@include media($largeLayoutBreakpoint) {
				display: table-cell;
				padding-top: $baseHalf;
			}
		}

		tr:not(:first-of-type):not(:last-of-type) th {
			@include media($largeLayoutBreakpoint) {
				border-top: none;
				border-bottom: none;
				border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
			}
		}

		tr:last-of-type th {
			@include media($largeLayoutBreakpoint) {
				border-top: none;
				border-bottom: none;
			}
		}
	}


	&__table--verticalHorizontalLayout,
	.ubf-html.no-js &__table--compareLayout {
		tr:not(:first-of-type) th {
			border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
			display: block;
			margin-bottom: $baseHalf;
			padding-top: $base1;
			text-align: center;
			white-space: nowrap;
			width: 100%;

			@include media($largeLayoutBreakpoint) {
				padding-top: $baseHalf;
				text-align: left;
				display: table-cell;
				width: auto;
			}

			&:last-child {
				padding-right: 0;
			}
		}


		tr:not(:first-of-type):not(:last-of-type) th {
			@include media($largeLayoutBreakpoint) {
				border-top: none;
				border-bottom: none;
				border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
			}
		}


		tr:last-of-type th {
			@include media($largeLayoutBreakpoint) {
				border-top: none;
				border-bottom: none;
			}
		}


		td {
			border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
			display: block;
			padding: $baseHalf $base2 $baseHalf 0;

			@include media($largeLayoutBreakpoint) {
				display: table-cell;
			}

			&:last-child {
				.ubf-body & {
					padding-right: 0;
				}
			}
		}
	}


	&__table--compareLayout {
		.ubf-html:not(.no-js) & {
			th {
				@include ubf-highlightColor(border-bottom-color);
				border-bottom-width: $tableHeaderBorderSize;
				border-bottom-style: solid;
				display: table-cell;
				vertical-align: top;
				padding: $baseHalf $base2 $baseHalf 0;

				&:last-child {
					padding-right: 0;
				}
			}

			tbody {
				display: flex;
				flex-direction: column;
				width: 100%;
			}

			tr {
				display: flex;
			}

			tr:first-child {
				th {
					display: block;
					width: staticColumnSize(11, 12, $gridGutterSmall);
					margin-right: $gridGutterSmall;
					flex-shrink: 0;

					@include media($ibridLayoutBreakpoint) {
						width: staticColumnSize(6, 12, $gridGutterSmall);
					}

					@include media($largeLayoutBreakpoint) {
						width: staticColumnSize(3, 12, $gridGutterSmall);
					}

					&:last-child {
						width: staticColumnSize(12, 12, $gridGutterSmall);

						@include media($ibridLayoutBreakpoint) {
							width: staticColumnSize(6, 12, $gridGutterSmall);
						}

						@include media($largeLayoutBreakpoint) {
							width: staticColumnSize(3, 12, $gridGutterSmall);
						}
					}
				}

				td {
					display: none;
				}
			}


			tr:last-child td {
				border-bottom: none;
			}

			tr:not(:first-child) {
				td {
					display: block;
					flex-shrink: 0;
					margin-right: $gridGutterSmall;
					width: staticColumnSize(11, 12, $gridGutterSmall);

					@include media($ibridLayoutBreakpoint) {
						width: staticColumnSize(6, 12, $gridGutterSmall);
					}

					@include media($largeLayoutBreakpoint) {
						width: staticColumnSize(3, 12, $gridGutterSmall);
					}
				}

				th ~ td {
					padding-top: $base1 + (getFontSize(base) * getLineHeight(base));
				}

				td:last-child {
					width: staticColumnSize(12, 12, $gridGutterSmall);
					margin-right: 0;

					@include media($ibridLayoutBreakpoint) {
						width: staticColumnSize(6, 12, $gridGutterSmall);
					}

					@include media($largeLayoutBreakpoint) {
						width: staticColumnSize(3, 12, $gridGutterSmall);
					}
				}

				th {
					position: absolute;
					left: 0;
					display: flex;
					border-bottom: none;
					white-space: nowrap;
					width: 0;
					padding-right: 0;
				}
			}

			tr:not(:first-child):not(:last-child) td {
				border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
			}
		}
	}


	th {
		display: none;
		font-weight: bold;
		text-align: left;
		position: relative;

		@include media($largeLayoutBreakpoint) {
			@include ubf-highlightColor(border-bottom-color);
			border-bottom-width: $tableHeaderBorderSize;
			border-bottom-style: solid;
			display: table-cell;
			vertical-align: top;
			padding: $baseHalf $base2 $baseHalf 0;
		}

		// &:last-child {
		// 	@include media($largeLayoutBreakpoint) {
		// 		padding-right: 0;
		// 	}
		// }
	}


	&__table--alternative th {
		@include media($largeLayoutBreakpoint) {
			border: 0;
		}

		&:first-child {
			@include media($largeLayoutBreakpoint) {
				padding-left: $base;
			}
		}
	}



	td {
		@include ease(color, $duration2);
		border-left: $tableHeaderBorderSize solid transparent;
		border-right: $tableHeaderBorderSize solid transparent;
		border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
		display: block;
		font-weight: normal;
		padding: $base 0;
		text-align: left;

		@include media($largeLayoutBreakpoint) {
			border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
			display: table-cell;
			padding: $baseHalf $base2 $baseHalf 0;
			vertical-align: top;
		}

		&.ubf-js-hidden {
			color: $colorUnselected;
		}

		&.ubf-js-highlighted {
			color: $colorBase;
		}

		&:last-child {
			@include ubf-highlightColor(border-bottom-color);
			border-bottom-width: $tableHeaderBorderSize;
			border-bottom-style: solid;

			.ubf-body & {
				@include media($largeLayoutBreakpoint) {
					border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
					padding-right: 0;
				}
			}
		}
	}




	&__table--alternative td:first-child {
		@include media($largeLayoutBreakpoint) {
			padding-left: $base;
		}
	}


	&__table--semesterTable td {
		border-bottom: 0;
		margin-right: $base1;

		&:first-child {
			flex-grow: 1;
			width: 100%;

			@include media($largeLayoutBreakpoint) {
				width: auto;
			}
		}

		// last child repeticion needed to override alternative variant
		&:last-child {
			border-bottom: 0;
		}
	}


	&__table--semesterTable th {
		font-weight: bold;
		display: table-cell;

		@include media($largeLayoutBreakpoint) {
			padding-bottom: $base1;
		}

		&:not(:first-child) {
			display: none;

			@include media($largeLayoutBreakpoint) {
				display: table-cell;
			}
		}
	}

	.ubf-form--tableSearch.ubf-js-sortable th {
		padding-left: $base2;
	}


	tr:last-of-type td {
		@include media($largeLayoutBreakpoint) {
			border-bottom: 0;
		}

		&:last-child {
			border-bottom: 0;
		}
	}


	// th content added dynamically in each cell in order to be displayed on small screens
	[data-ubf-nested-header] {
		font-weight: bold;

		@include media($largeLayoutBreakpoint) {
			display: none;
		}
	}

	&__table--verticalHorizontalLayout.ubf-js-static [data-ubf-nested-header] {
		display: none;
	}


	table.ubf-ugc__table--applicationTile {
		// NOTE: this seems like a duplication but we need to override existing attributes on lower breakpoint
		display: block;

		@include media($hr4youTableLayoutBreakpoint) {
			display: table;
		}

		// th content added dynamically in each cell in order to be displayed on small screens
		& [data-ubf-nested-header] {
			font-weight: bold;
			display: block;

			@include media($hr4youTableLayoutBreakpoint) {
				display: none;
			}
		}

		& tbody,
		& caption {
			display: block;

			@include media($hr4youTableLayoutBreakpoint) {
				display: table-row-group;
			}
		}

		& tr {
			display: block;

			@include media($hr4youTableLayoutBreakpoint) {
				display: table-row;
			}

			@include onHover {
				td {
					@include media($largeLayoutBreakpoint) {
						background-color: $colorHighlightGeneric;
					}
				}
			}

			&.ubf-js-hidden {
				display: none;
			}

			& .ubf-ugc__tableLink:not(.ubf-ugc__tableLink--mobile) {
				@include ubf-font(base);
				display: block;
				font-weight: normal;
				text-decoration: none;
				pointer-events: none;

				@include onHover {
					color: inherit;
				}

				@include media($hr4youTableLayoutBreakpoint) {
					pointer-events: all;
				}
			}
		}

		& tr:last-of-type td {
			border-bottom: $tableCellBorderSize solid $colorTableCellBorder;

			@include media($hr4youTableLayoutBreakpoint) {
				border-bottom: 0;
			}

			&:last-child {
				border-bottom: 0;
			}
		}

		& th {
			display: none;
			font-weight: bold;
			text-align: left;
			border-bottom: none;
			white-space: nowrap;

			@include media($hr4youTableLayoutBreakpoint) {
				@include ubf-highlightColor(border-bottom-color);
				border-bottom-width: $tableHeaderBorderSize;
				border-bottom-style: solid;
				display: table-cell;
				vertical-align: top;
				padding: $baseHalf $base2 $baseHalf 0;
			}

			&:last-child {
				@include media($hr4youTableLayoutBreakpoint) {
					padding-right: 0;
				}
			}

			&.ubf-ugc__mobileCell {
				display: none;
			}
		}

		& td {
			border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
			display: block;
			font-weight: normal;
			padding: $base 0;
			text-align: left;

			@include media($hr4youTableLayoutBreakpoint) {
				border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
				display: table-cell;
				padding: $baseHalf $base2 $baseHalf 0;
				vertical-align: top;
			}

			&:last-child {
				@include ubf-highlightColor(border-bottom-color);
				border-bottom-width: $tableHeaderBorderSize;
				border-bottom-style: solid;

				.ubf-body & {
					@include media($hr4youTableLayoutBreakpoint) {
						border-bottom: $tableCellBorderSize solid $colorTableCellBorder;
						padding-right: 0;
					}
				}
			}

			&.ubf-ugc__mobileCell {
				display: block;

				@include media($hr4youTableLayoutBreakpoint) {
					display: none;
				}
			}
		}
	}


	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ol,
	ul,
	dl,
	blockquote {
		.ubf-contentBlock--form:not(.ubf-contentBlock--formSmall) &,
		.ubf-contentBlock--textFull & {
			@include media($largeLayoutBreakpoint) {
				max-width: staticColumnSize(6, 12, $gridGutterLarge);
			}

			.ubf-body--internal & {
				@include media($largeLayoutBreakpoint) {
					max-width: staticColumnSize(6, 9, $gridGutterLarge);
				}
			}

			.ubf-body--ekvv & {
				@include media($largeLayoutBreakpoint) {
					max-width: ubf-ekvvMaxSize(5, 9);
				}
			}
		}

		.ubf-body--ekvv & {
			@include media($largeLayoutBreakpoint) {
				max-width: ubf-ekvvMaxSize(5, 9);
			}
		}
	}


	* + a > figure {
		margin-top: 1em;
	}


	// space before the headings
	* + h1,
	* + h2,
	* + h3,
	* + h4,
	* + h5,
	* + h6 {
		margin-top: $base3;
	}


	// space before all common elements in the content
	* + table,
	* + figure,
	* + p,
	* + ol,
	* + ul,
	* + dl,
	dd + dd,
	dt + dt {
		margin-top: 1em;
	}


	// space between list items
	li + li {
		margin-top: $base;
	}


	// special style between definition list items
	dd ~ dt {
		border-top: $tableCellBorderSize solid $colorTableCellBorder;
		margin-top: $base2;
		padding-top: $base2;

		.ubf-rxml & {
			border-top: none;
		}
	}

	.ubf-ugc__dl--alternative {
		dd + dt {
			border: none;
		}

		dt {
			font-weight: normal;
			color: $colorFormText;
		}

		dd {
			font-weight: bold;
		}
	}

	.ubf-ugc__dl--borderless {
		dd + dt {
			border: none;
		}
	}

	.ubf-ugc__dl--compact dd + dt,
	.ubf-ugc__dl--compact dd + dd,
	.ubf-ugc__dl--floating dd + dt,
	.ubf-ugc__dl--floating dd + dd {
		border-top: 0;
		margin-top: 0;
		padding-top: 0;
	}

	.ubf-ugc__dl--alphabeticalList {
		& dd + dt {
			border-top: $tableCellBorderSize solid $colorAlphabeticalDlBorder;
		}

		& dt {
			margin-bottom: $base1;
		}

		& dd:last-of-type {
			margin-bottom: $base4;
		}
	}


	.ubf-ugc-dt--application + .ubf-ugc-dt--application,
	.ubf-ugc-dd--application ~ .ubf-ugc-dd--application {
		display: inline;
	}


	// special margin around the blockquote
	* + blockquote,
	blockquote + * {
		margin-top: $base2;
	}

	// special margin around hr separator
	* + hr,
	hr + * {
		margin-top: $base2;
	}

	&__text {
		&--helpText {
			@include ubf-font('helpText');
		}

		&--date {
			@include ubf-font('date');
		}

		&--important {
			font-weight: bold;
			color: $colorTextImportant;
		}

		&--application {
			display: inline;
		}

		h4 + &--application + &--application + &--application {
			display: block;
			margin-top: 0;
		}
	}
}
