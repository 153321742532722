.ubf-page {
	@include ubf-container();

	.ubf-body--internal & {
		@include media($largeLayoutBreakpoint) {
			@include staticColumns();
		}
	}

	.ubf-body--ekvv & {
		@include media($largeLayoutBreakpoint) {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
		}
	}


	&__secondaryMenu {
		display: none;

		@include media($largeLayoutBreakpoint) {
			display: block;
			margin: 0;
			position: relative;
			width: staticColumnSize(3, 12, $gridGutterLarge);
			z-index: 4;
		}

		.ubf-body--ekvv & {
			@include media($largeLayoutBreakpoint) {
				flex-grow: 0;
				flex-shrink: 0;
				max-width: ubf-ekvvMaxSize(3, 12);
			}
		}
	}


	&__sections {
		width: 100%;

		.ubf-body--internal & {
			@include media($largeLayoutBreakpoint) {
				width: staticColumnSize(9, 12, $gridGutterLarge);
			}

			&:first-child {
				@include media($largeLayoutBreakpoint) {
					margin-left: staticColumnPush(3, 12, $gridGutterLarge);
				}
			}
		}

		.ubf-body--ekvv & {
			@include media($largeLayoutBreakpoint) {
				width: auto;
				flex-grow: 1;
				flex-shrink: 1;
				margin-left: $gridGutterLarge;
			}
		}

		.ubf-page--filteredSearch & {
			@include media($largeLayoutBreakpoint) {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
			}
		}
	}


	&__section {
		// @include ubf-container();
		position: relative;
		z-index: 4;

		&--cover {
			// it has to be a square on portrait
			height: 100vw;
			left: 0;
			position: fixed;
			top: 0;
			right: 0;
			z-index: 0;

			.ubf-body--miniCover & {
				height: $paddingTopMiniCoverSmall;

				@include media('landscape') {
					height: $paddingTopMiniCoverSmallLandscape;
				}

				@include media($largeLayoutBreakpoint) {
					height: $paddingTopMiniCover;
				}
			}
			.ubf-body--microCover & {
				height: $paddingTopMicroCoverSmall;

				@include media('landscape') {
					height: $paddingTopMicroCoverSmallLandscape;
				}

				@include media($largeLayoutBreakpoint) {
					height: $paddingTopMicroCover;
				}
			}

			@include media('landscape') {
				height: 100vh;
			}

			@include media('landscape', $largeLayoutBreakpoint) {
				height: calc(#{$paddingTopDefaultLarge} - #{$menuHeight});
			}

			.ubf-body--home & {
				@include media('landscape', $largeLayoutBreakpoint) {
					height: calc(#{$paddingTopHomeLarge} - #{$menuHeight});
				}

				html.ubf-js-mobile & {
					@include media('landscape', $largeLayoutBreakpoint) {
						height: calc(#{$paddingTopHomeLargeMobile} - #{$menuHeight});
					}
				}
			}


			// remove the forced aspect-ratio
			&::before {
				@include media('landscape') {
					display: none;
				}

				@include media($largeLayoutBreakpoint) {
					display: none;
				}
			}
		}

		&--searchFilters {
			@include media($largeLayoutBreakpoint) {
				border-right: solid 1px $colorEkvvFilterBorder;
				flex-grow: 0;
				flex-shrink: 0;
				max-width: ubf-ekvvMaxSize(3, 12);
				width: staticColumnSize(3, 9, $gridGutterLarge, $base2);
				margin-top: -$base2;
				padding-top: $base2;
			}
		}

		.ubf-page--filteredSearch &--searchFilters + & {
			@include media($largeLayoutBreakpoint) {
				width: auto;
				flex-grow: 1;
				flex-shrink: 1;
				margin-left: $gridGutterLarge;
			}
		}
	}


	&__separator {
		@include ubf-highlightColor(background-color);
		border: 0;
		height: $boxBorderSize;
		margin-bottom: $sectionVerticalSpaceSmall;
		margin-top: 0;
		position: relative;
		width: 100%;
		z-index: 4;

		@include media($largeLayoutBreakpoint) {
			margin-bottom: $sectionVerticalSpaceLarge;
		}

		&:last-child {
			margin-bottom: 0;

			@include media($largeLayoutBreakpoint) {
				margin-bottom: 0;
			}
		}
	}
}
