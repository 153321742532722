.ubf-slideshowNavigation {
	.ubf-section--cover & {
		padding-bottom: $base3;

		@include media($largeLayoutBreakpoint) {
			padding-bottom: $base5;
		}
	}
	.ubf-gallery__slideshow & {
		z-index:100;

		& .ubf-slideshowNavigation__item--prev .ubf-slideshowNavigation__link {
			justify-content: flex-start;
		}
		& .ubf-slideshowNavigation__item--next .ubf-slideshowNavigation__link {
			justify-content: flex-end;
		}
	}


	&__icon {
		@include ease(background-color, $duration2);
		@include ubf-highlightColor(color);
		border: $boxBorderSize solid currentColor;
		display: inline-block;
		height: $galleryNavigationIconSize;
		position: relative;
		width: $galleryNavigationIconSize;
		pointer-events: none;

		.ubf-body:not(.ubf-body--faculty) & {
			color: $colorHighlightBase;
		}

		.ubf-slideshowNavigation__item--prev & {
			border: 0;
			transform: rotate(180deg);
		}

		.ubf-slideshowNavigation__item--next & {
			border: 0;
		}

		.ubf-slideshowNavigation__link.ubf-js-current & {
			background-color: currentColor;
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__item {
		line-height: 0;
		margin-right: $base2;
		pointer-events: none;
		flex-grow: 1;

		&:last-child {
			margin-right: 0;
		}

		.ubf-section--cover & {
			margin: 0;
		}

		&--prev,
		&--next {
			html.no-js & {
				display: none;
			}
		}
	}


	&__items {
		display: flex;
		flex-direction: row;
		justify-content: center;
		pointer-events: none;

		.ubf-section--cover & {
			justify-content: space-between;
			@include media($largeLayoutBreakpoint) {
				width: staticColumnSize(7, 12, $gridGutterLarge);
			}
		}

		.ubf-modal & {
			pointer-events: none;
			justify-content: space-between;
		}
	}


	&__link {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: $base3;
		pointer-events: all;
		width: 100%;

		&:not(:hover):focus {
			outline: $focusOutlineStyle $colorInverted 1px;
			outline-offset: $baseHalf;
		}

	}
}
