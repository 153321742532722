.ubf-slide {
	@include ease(opacity visibility, $duration4);
	@include fadeOut();
	outline: 0;
	user-select: text;
	z-index: 1;

	&.ubf-js-current {
		@include fadeIn();
		z-index: 100;
	}

	html.no-js &:target {
		@include fadeIn();
		z-index: 101;
	}


	&__caption {
		@include ubf-font(caption);
		color: $colorBgBase;
		left: 0;
		padding: $base 0 0 0;
		position: absolute;
		text-align: right;
		top: 100%;
		width: 100%;
	}


	&__content {
		@include ubf-container();
		@include staticColumns();
		align-items: flex-end;
		height: 100%;
		padding-bottom: $base3 + $galleryNavigationIconSize + $base3;
		position: relative;
		pointer-events: none;
		z-index: 2;

		@include media($largeLayoutBreakpoint) {
			padding-bottom: $base5;
		}
	}


	&__image {
		backface-visibility: hidden;
		// height: 100%;
		bottom: 0;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.ubf-section--cover & {
			pointer-events: none;
			@include ubf-coverImg();
		}
	}


	&__picture {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;


		.ubf-gallery--slideshow & + & {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 2;
		}
	}

	&__image-description {
		bottom: 0;
		position: absolute;
		width: 100%;
		background: white;
		opacity: 0.75;
		padding: 0 $base1;
		@include ubf-font(small);

		@include media($largeLayoutBreakpoint) {
			@include ubf-font(base);
			opacity: 1;
		}

	}

	&__textBox {
		@include ubf-coverTextBox();
		pointer-events: all;
	}
}
